import React, { ChangeEvent, FormEvent, useState } from "react";

import usePoll from "~/hooks/usePoll";

import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Note from "~/components/TextBox/Note";
import Poll, { Response } from "~/components/poll";
import { InputField } from "~/components/Inputs/";
import { StudentCard, TeacherCard } from "~/components/content-card";

import POLL_RESPONSES from "~/consts/poll-responses";


interface ResponseDataProps {
  label: string;
  responses: Response[] | undefined;
}

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);
  const [classCode, setClassCode] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const {
    classResponses,
    error,
    getNumberOfResponses,
    getNumberOfResponsesPercentage,
    getPollResponses,
    levelResponses,
    loading
  } = usePoll();

  const viewResults = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await getPollResponses(classCode, "HS");
      setSubmitted(true);
    } catch(err) {
      console.log(err);
    };
  };

  const ResponseData = ({ label, responses }: ResponseDataProps): JSX.Element => {
    const numberOfResponses: number = getNumberOfResponses(responses, label.toUpperCase().replace(/\s/g, "_"));
    const percentage: number = getNumberOfResponsesPercentage(responses, numberOfResponses);

    return (
      <div>{label}: {numberOfResponses} <span>({percentage}%)</span></div>
    );
  };

  return (
      <LessonLayout
        current_step={1}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 1</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>Suppose you do an internet search that results in a lot of hits. Can you trust the information found on the sites? Researchers have investigated this question. What do you think they found? Give your opinion by voting on the following question.</p>

              <Poll color="hs" responses={POLL_RESPONSES} question="How accurate is health information found on the internet?" disabled />

              <p>After you vote, wait for a class discussion led by your teacher.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Students will now begin Lesson 2 on the website. In this step, students vote on their views of information accuracy. The results will appear in the teacher materials. Display the results of the vote and assess students' first impressions about accuracy of information on the internet.</p>

              <form className="mt-4" onSubmit={viewResults}>
                <InputField
                  label="Class code"
                  name="Class code"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setClassCode(e.target.value)}
                />
                <Button
                  action="secondary"
                  className="mt-3"
                  disabled={error}
                  color="indigo"
                  loading={loading}
                  style={{ minWidth: "200px" }}
                  type="submit"
                  title="Poll Results"
                >
                  Poll Results
                </Button>
              </form>
              {submitted && !error
                ?
                  <div>
                    <h2>Poll Results</h2>
                    <h3>Class Responses</h3>

                    <ResponseData label="Very accurate" responses={classResponses} />
                    <ResponseData label="Mostly accurate" responses={classResponses} />
                    <ResponseData label="Somewhat accurate" responses={classResponses} />
                    <ResponseData label="Not very accurate" responses={classResponses} />

                    <h3>National Responses</h3>

                    <ResponseData label="Very accurate" responses={levelResponses} />
                    <ResponseData label="Mostly accurate" responses={levelResponses} />
                    <ResponseData label="Somewhat accurate" responses={levelResponses} />
                    <ResponseData label="Not very accurate" responses={levelResponses} />
                  </div>
                :
                  submitted && error
                    ? <Note color="error" headerText="" type="error">{error}</Note>
                    : null
              }
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
