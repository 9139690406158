import { useState } from "react";

import { post } from "~/helpers/api";

import { Response } from "~/components/poll";


type Level = "HS" | "MS";

const getNumberOfResponses = (responses: Response[] | undefined, label: string): number => {
  if (!responses) return 0;

  return responses?.filter((response) => response.response === label).length;
};

const getNumberOfResponsesPercentage = (responses: Response[] | undefined, num: number): number => {
  if (!responses || responses.length === 0) return 0;

  const percentage: number = Math.round(num / responses?.length * 100);

  return percentage;
};

const usePoll = () => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [classResponses, setClassResponses] = useState<Response[]>();
  const [levelResponses, setLevelResponses] = useState<Response[]>();

  const getPollResponses = async (classCode: string, level: Level) => {
    try {
      setLoading(true);

      const code: string = classCode.trim();

      const { data: classData }  = await post("/getPollResponsesByCode", { code });
      setClassResponses(classData);

      const { data: levelData }  = await post("/getPollResponsesByLevel", { level });
      setLevelResponses(levelData);

      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError("There was an issue getting the poll results. Please report an issue by clicking the button in the top right.");
      console.log(err);
    };
  }

  return {
    classResponses,
    error,
    getNumberOfResponses,
    getNumberOfResponsesPercentage,
    getPollResponses,
    levelResponses,
    loading
  }
};

export default usePoll;
